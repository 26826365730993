import React from "react";
import styles from "./FooterV3.module.scss";
import { ReactComponent as BeatBreadLogo } from "../../assets/SVGs/logos/beatBread-logo.svg";
import { HOME, ARTIST_ENROLLMENT, TERM_OF_USE , PRIVACY_POLICY, FUNDING_NETWORK, DEAL_COMPARISON } from "../../routes/constants";
import { SOCIAL_LINKS } from "./constants";
import { Link } from "react-router-dom";
import { get, invoke } from "lodash";
import Divider from "../../components/Divider";
import { Button2, SnapSection } from "../../components";
import { DRAWER_NAVIGATION } from "../../components/HeaderV3/constants";

let currentYear = new Date().getFullYear();

const FooterV3 = () => (
    <div id="footer" className={styles.container}>
      <div className={styles.upperFooter}>
        <div className={styles.navContainer}>
          <div className={styles.logoContainer}>
            <Link to={HOME} className={styles.logo}>
              <BeatBreadLogo />
            </Link>
            <div className={styles.loginContainer}>
              <Button2 light xs tertiary to={`${ARTIST_ENROLLMENT}?isLogin=true`} className={styles.loginBtn}><p>Log&nbsp;In</p></Button2>
              <div className={styles.ctaContainer}>
                <Button2 light xs outline to={DEAL_COMPARISON} className={styles.loginBtn}><p>Compare Offers</p></Button2>
                <Button2 light xs to={FUNDING_NETWORK} className={styles.loginBtn}><p>Get Funding</p></Button2>
              </div>
            </div>
          </div>
          <Divider horizontal accent/>
          <div className={styles.navLinks}>
            <div className={styles.menuContainer}>
              {DRAWER_NAVIGATION.map((option, i) => (
                <div key={`${option.title}-${i}`} className={styles.menu}>
                  <Link to={get(option, "link", "")}>{get(option, "title")}</Link>
                  {!!get(option, "options.length") && (
                    <div className={styles.subMenu}>
                      {invoke(option, "options.map", (op) => (
                        <Link key={get(op, "title")} to={get(op, "to", "")}>
                          {get(op, "title", "")}
                        </Link>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div className={styles.socialContainer}>
              <div className={styles.socialLinks}>
                {SOCIAL_LINKS.map((link) => (
                  <a href={get(link, "to", "")} key={`${get(link, "to")}-desktop`}>
                    {get(link, "icon", "")}
                  </a>
                ))}
              </div>
              <div className={styles.textSocials}>
                <div className={styles.address}>94 S Mall Drive #201, St George, UT, 84790</div>
                <div className={styles.xsTextSocials}>
                  <div className={styles.rights}>
                    <a href={TERM_OF_USE}>Terms of Service</a>
                    <div>-</div>
                    <a href={PRIVACY_POLICY}>Privacy Policy</a>
                  </div>
                  <div className={styles.copyright}>All Rights Reserved © {currentYear}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>
);

const FooterV3Snap = () => (
  <SnapSection className={styles.footer} short>
    <div id="footer" className={styles.container}>
      <div className={styles.upperFooter}>
        <div className={styles.navContainer}>
          <div className={styles.logoContainer}>
            <Link to={HOME} className={styles.logo}>
              <BeatBreadLogo />
            </Link>
            <div className={styles.loginContainer}>
              <Button2 light xs tertiary to={`${ARTIST_ENROLLMENT}?isLogin=true`} className={styles.loginBtn}><p>Log&nbsp;In</p></Button2>
              <div className={styles.ctaContainer}>
                <Button2 light sm outline to={DEAL_COMPARISON} className={styles.loginBtn}><p>Compare Offers</p></Button2>
                <Button2 light sm to={FUNDING_NETWORK} className={styles.loginBtn}><p>Get Funding</p></Button2>
              </div>
            </div>
          </div>
          <Divider horizontal accent/>
          <div className={styles.navLinks}>
            <div className={styles.menuContainer}>
              {DRAWER_NAVIGATION.map((option, i) => (
                <div key={`${option.title}-${i}`} className={styles.menu}>
                  <Link to={get(option, "link", "")}>{get(option, "title")}</Link>
                  {!!get(option, "options.length") && (
                    <div className={styles.subMenu}>
                      {invoke(option, "options.map", (op) => (
                        <Link key={get(op, "title")} to={get(op, "to", "")}>
                          {get(op, "title", "")}
                        </Link>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div className={styles.socialContainer}>
              <div className={styles.socialLinks}>
                {SOCIAL_LINKS.map((link) => (
                  <a href={get(link, "to", "")} key={`${get(link, "to")}-desktop`}>
                    {get(link, "icon", "")}
                  </a>
                ))}
              </div>
              <div className={styles.textSocials}>
                <div className={styles.address}>94 S Mall Drive #201, St George, UT, 84790</div>
                <div className={styles.xsTextSocials}>
                  <div className={styles.rights}>
                    <a href={TERM_OF_USE}>Terms of Service</a>
                    <div>-</div>
                    <a href={PRIVACY_POLICY}>Privacy Policy</a>
                  </div>
                  <div className={styles.copyright}>All Rights Reserved © {currentYear}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  </SnapSection>
);

export {FooterV3, FooterV3Snap};
