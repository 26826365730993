import React from "react";
import Slider from "react-slick";
import styles from "./LabelTestimonials.module.scss";
import { ReactComponent as IconChevronUp } from "../../assets/SVGs/chevron-up-white.svg";
import { ReactComponent as IconChevronDown } from "../../assets/SVGs/chevron-down-white.svg";
import { ReactComponent as DoubleArrows } from "../../assets/SVGs/arrows-two.svg";
import SASS from "../../sass/_exports.scss";
import { bounceIn, Button2 } from "../../components";
import { motion } from "motion/react"
import { imgList, testimonialList } from "./constants";



const LabelTestimonials = (props) => {
  return (
    <motion.div className={`${styles.NewsContainer} ${props.className} ${props.artist ? styles.artist : ""}`} {...bounceIn}>
      {props.imageSlider ? (
        <div className={styles.subContainer}>
        <div className={styles.titleContainer} style={{ display: props.title || props.link ? "flex" : "none" }}>
          <div className={`${styles.title}`}>
            <h3
              className={`${styles.heading} ${props.light ? styles.light : styles.dark}`}
              style={{ display: props.title ? "flex" : "none" }}
            >
              {props.title}
            </h3>
            <h6 className={`${styles.subtitle} ${props.light ? styles.light : styles.dark}`}>
              {props.subtitle}
            </h6>
          </div>
          { props.link ? (
            <Button2 tertiary to={props.link} light={props.light ? true : false} dark={props.light ? false : true} className={styles.lnkBtnAll}>
            <p>{props.buttonTitle ? props.buttonTitle : "View All"}</p>
            <DoubleArrows />
          </Button2>
          ): ""}
          
        </div>
        <div className={`${styles.carouselContainer} ${props.light ? styles.light : styles.dark}`}>
          <Slider
            dots={true}
            infinite={false}
            slidesToShow={2}
            slidesToScroll={2}
            nextArrow={<IconChevronUp />}
            prevArrow={<IconChevronDown />}
            responsive={[
              {
                breakpoint: parseInt(SASS.MD_N),
                settings: { slidesToShow: 1.1, slidesToScroll: 1 },
              },
              {
                breakpoint: parseInt(SASS.LG_XL_N),
                settings: { slidesToShow: 2, slidesToScroll: 2 },
              },
            ]}
          >
            {imgList.map((e, i) => (
              <div key={i} className={styles.testimonial}>
                <div className={styles.imgWrap}>
                  <div className={styles.testimonialImg} style={{backgroundImage: `url(${e.image})`}}/>
                  <p className={styles.quote}>{e.quote}</p>
                </div>
                <div className={styles.footer}>
                  <div className={styles.footerText}>
                    <h3>{e.author}</h3>
                    <h4>{e.partner} &#40;{e.country}&#41;</h4>
                  </div>
                  {e.logo}
                </div>
              </div>
            ))}
          </Slider>
          
        </div>
      </div>
      ) : (
        <div className={styles.subContainer}>
          <div className={styles.titleContainer}>
            <div className={`${styles.title}`}>
              <h3
                className={`${styles.heading} ${props.light ? styles.light : styles.dark}`}
                style={{ display: props.title ? "flex" : "none" }}
              >
                {props.title ? props.title : "Label Testimonials"}
              </h3>
              <h6 className={`${styles.subtitle} ${props.light ? styles.light : styles.dark}`}>
                {props.subtitle}
              </h6>
            </div>
            { props.link ? (
              <Button2 tertiary to={props.link} light={props.light ? true : false} dark={props.light ? false : true} className={styles.lnkBtnAll}>
              <p>{props.buttonTitle ? props.buttonTitle : "View All"}</p>
              <DoubleArrows />
            </Button2>
            ): ""}
            
          </div>
          <div className={`${styles.carouselContainer} ${props.light ? styles.light : styles.dark}`}>
            <Slider
              dots={true}
              infinite={false}
              slidesToShow={2}
              slidesToScroll={2}
              nextArrow={<IconChevronUp />}
              prevArrow={<IconChevronDown />}
              responsive={[
                {
                  breakpoint: parseInt(SASS.MD_N),
                  settings: { slidesToShow: 1.1, slidesToScroll: 1 },
                },
                {
                  breakpoint: parseInt(SASS.LG_XL_N),
                  settings: { slidesToShow: 2, slidesToScroll: 2 },
                },
              ]}
            >
              {testimonialList.map((e, i) => (
                <div key={i} className={styles.testimonial}>
                  <p className={styles.quote}>{e.quote}</p>
                  <div className={styles.footer}>
                    <div className={styles.footerText}>
                      <h3>{e.author}</h3>
                      <h4>{e.partner} &#40;{e.country}&#41;</h4>
                    </div>
                    {e.logo}
                  </div>
                </div>
              ))}
            </Slider>
            
          </div>
        </div>
      )} 
    </motion.div>
  );
};

export default LabelTestimonials;
