const searchStyle = {
    singleValue: (provided) => ({
      ...provided,
      alignItems: "center",
    }),

    control: (base, state) => ({
      ...base,
      outline: state.isFocused ? "none" : state.isHover ? "none" : "none",
      borderRadius: "0px",
      borderWidth: state.isFocused ? "0px 0px 2px 0px" : "0px 0px 1px 0px",
      background: "transparent",
      boxShadow: "none",
      padding: 0,
      width: "100%",
      minHeight: "45px",
      borderColor: state.isFocused ? "#5849A6" : "#8A80C1",
      "&:hover": { borderColor: "none", outline: "none" },
    }),

    loadingIndicator: (base) => ({
      ...base,
      marginRight: 30,
    }),

    valueContainer: (base) => ({
      ...base,
      padding: 0,
      color: "#352C64",
      cursor: "text",
      marginLeft: 0,
      marginRight: 0,
      outline: "none",
    }),

    input: (base) => ({
        ...base,
        color: "#5849a6",
        font: "normal 700 14px/142% Poppins, sans-serif",
        margin: 0,
        padding: 0,
        outline: "none",
    }),

    //main container for artist search popup
    menu: (base, state) => ({
        ...base,
        borderColor: state.selectProps.inputValue.length
          ? "#ACA4D3"
          : "transparent",
        borderWidth: "1.5px",
        borderStyle: "solid",
        outline: "none",
        boxSizing: "border-box",
        boxShadow: "none",
        borderRadius: "6px",
        overflow: "hidden",
        margin: "5px 0 0 0",
        backgroundColor: state.selectProps.inputValue.length
          ? "#ffffff"
          : "transparent",
        width: state.selectProps.inputValue.length ? "100%" : "0",
      }),
    
      option: (base) => ({
        ...base,
        backgroundColor: "transparent",
        padding: "0",
        margin: "0",
        "&:active": { backgroundColor: "transparent" },
      }),
    
      menuList: (base) => ({
        ...base,
        padding: "5px 0px",
        margin: "0px 5px 0px 0px",
        color: "#black",
      }),
    };

  export default searchStyle;