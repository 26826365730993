import React from "react";
import "./App.scss";
import MainRoutes from "./routes/index";
import "./components/ThirdPartyIntegrations/DataDog";
import { useAgent } from "./hooks";
import Hotjar from "@hotjar/browser";
import { MotionConfig } from "framer-motion"

if (process.env.REACT_APP_HOTJAR_ID) {
  const hotjarVersion = 6;
  Hotjar.init(process.env.REACT_APP_HOTJAR_ID, hotjarVersion);
}

function App() {
  const HOME = useAgent();

  return (
    <MotionConfig reducedMotion="user">
      <MainRoutes home={HOME} />
    </MotionConfig>
  );
}

export default App;
