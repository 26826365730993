export const EMAIL_REGEX =
  /^[A-Za-z0-9_+#*&?$!.-]+@[A-Za-z0-9_+#*&?$!.-]+\.[A-Za-z]{2,}$/;

export const WEBSITE_REGEX = /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/;
export const PHONE_REGEX = /^[+]?[0-9]?[-\s.]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;

export const STAGES = {
  INITIAL: "initial",
  SUCCESS: "success",
  ERROR: "error",
};

export const typeOptions = {
  "Artist": false,
  "Label": false,
};

export const switchOptions = {
  "Yes": false,
  "No": false,
  "Unsure": false,
};
