import { Pagination } from "swiper";
import styles from "./VerticalSwiper.module.scss";
import { ReactComponent as UpArrow } from "../../assets/SVGs/chevron-up-white.svg";
import { ReactComponent as DownArrow } from "../../assets/SVGs/chevron-down-white.svg";

import React, { useCallback, useRef } from 'react';

import "swiper/css";
import "swiper/css/bundle";

// import required modules
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Keyboard, Mousewheel } from "swiper/core";
SwiperCore.use([Keyboard, Mousewheel]);

//{props.children || "EMPTY"}

function VerticalSwiper(props) {

    const sliderRef = useRef(null);

    const handlePrev = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slidePrev();
    }, []);

    const handleNext = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slideNext();
    }, []);

  return (
    <div className={`${styles.container} ${props.light ? styles.light : styles.dark}`}>
        <Swiper
            mousewheel={{
                forceToAxis: true,
                releaseOnEdges: true,
            }}
            keyboard={true}
            direction={'vertical'}
            ref={sliderRef}
            pagination={{
                el: '.swiper-pagination',
                clickable: true,
                bulletActiveClass: styles.activeBullet,
                bulletClass: styles.bullet,
            }}
            modules={[Pagination]}
            className={`${styles.mySwiper} ${props.className}`}
        >
            { props.children ? (
                <div>
                    {Object.entries(props.children).map((e, i) => {
                        return (
                            <SwiperSlide key={i} className={styles.swiperSlide}>{props.children[i]}</SwiperSlide>
                        );
                    })}
                  </div>
                ) : null }
        </Swiper>
        <div className={styles.navigation}>
            <div className={`swiper-pagination ${styles.swiperPagination}`}></div>
            <div className={styles.arrows}>
                <div className={styles.prevArrow} onClick={handlePrev}><UpArrow/></div>
                <div className={styles.nextArrow} onClick={handleNext}><DownArrow/></div>
            </div>
        </div>
    </div>
  );
}

export default VerticalSwiper;