import React from "react";
import styles from "./BenefitCard.module.scss";
import variables from "../../mixins.scss";

function BenefitCard(props) {
  return (
    <div className={`${styles.benefitCard} ${props.light ? styles.light: null} ${props.large ? styles.large: null}`}>
        <svg id={styles.svgGradient} aria-hidden="true" focusable="false"><linearGradient id="purpleGradient" x2="1" y2="1"><stop offset="-11.24%" stop-color={variables.blueVioletColor}></stop><stop offset="59.06%" stop-color="#7965BF"></stop></linearGradient></svg>
        {props.icon}
        <div className={styles.textContainer}>
            <h5 className={styles.benefitTitle}>{props.title}</h5>
            <p className={styles.benefitDescription}>{props.description}</p>
        </div>
    </div>
  );
}

export default BenefitCard;
