import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { Pagination, PaginationItem, Stack } from "@mui/material";
import { ArticleCard } from "../ArticlePage/helper";
import styles from "./KnowledgeCenter.module.scss";
import { ReactComponent as IconChevronUp } from "../../assets/SVGs/chevron-right.svg";
import { ReactComponent as IconChevronDown } from "../../assets/SVGs/chevron-left.svg";
import React from "react";
import {
  ADVANCING_INDEPENDENCE,
  GLOSSARY,
  PRESS,
  ARTIST_LIKE_YOU,
  GetIframeVideo,
  KNOWLEDGECENTER,
  LEARN_MORE,
  YT_VIDEO_ID_REGEX,
  FAQ,
} from "../../routes/constants";
import { ReactComponent as IconChevron } from "../../assets/SVGs/arrows-two.svg";

import { Link } from "react-router-dom";
import { bounceIn, Button2 } from "../../components";
import { motion } from "motion/react"
import ScrollToTop from "../../routes/ScrollToTop";

export const RenderFeaturedSec = (props) => {
  return (
    <>
      {props.title && (
        <div
          className={`${styles.featuredContainer} ${
            props.padding && styles.padding
          }`}
        >
          <div className={styles.leftContainer}>
            <p>Featured:</p>
            <p>{props?.title}</p>
            <p>
              {props?.type === "Articles" ? (
                <Link
                  to={`${KNOWLEDGECENTER}/${props?.name}`}
                  onClick={() => {
                    sessionStorage.setItem(
                      "listData",
                      JSON.stringify({
                        id: props?.name,
                        category: props?.category,
                      })
                    );
                  }}
                >
                  Read More
                </Link>
              ) : (
                <a href={props?.href} target="_blank" rel="noreferrer">
                  Play on Youtube
                </a>
              )}{" "}
              <IconChevron />
            </p>
          </div>
          {props?.type === "Articles" ? (
            <div className={styles.imgContainer}>
              <img src={props?.image} alt="" />
            </div>
          ) : (
            <div className={styles.rightDiv}>
              {props?.href.match(YT_VIDEO_ID_REGEX) && (
                <div className={styles.iframeVideo}>
                  <GetIframeVideo
                    className={`${styles.iframe} `}
                    title="YouTube video player"
                    url={props?.href}
                    loading="lazy"
                  />
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export const advancingResourcesLink = [
  {
    linkName: "Knowledge Center",
    linkTo: KNOWLEDGECENTER
  },
  {
    linkName: "Artists Like You",
    linkTo: ARTIST_LIKE_YOU
  },
  {
    linkName: "Learn More",
    linkTo: LEARN_MORE
  },
];

export const artistLikeYouResourcesLink = [
  {
    linkName: "Knowledge Center",
    linkTo: KNOWLEDGECENTER
  },
  {
    linkName: "Advancing Independence",
    linkTo: ADVANCING_INDEPENDENCE
  },
  {
    linkName: "Learn More",
    linkTo: LEARN_MORE
  },
];

export const learnMoreResourcesLink = [
  {
    linkName: "Knowledge Center",
    linkTo: KNOWLEDGECENTER
  },
  {
    linkName: "Advancing Independence",
    linkTo: ADVANCING_INDEPENDENCE
  },
  {
    linkName: "Artists Like You",
    linkTo: ARTIST_LIKE_YOU
  },
];

export const tagsResourcesLink = [
  {
    linkName: "Advancing Independence",
    linkTo: ADVANCING_INDEPENDENCE
  },
  {
    linkName: "Artists Like You",
    linkTo: ARTIST_LIKE_YOU
  },
  {
    linkName: "Learn More",
    linkTo: LEARN_MORE
  },
];

export const knowledgeCenterResourcesLink = [
  {
    linkName: "Term Glossary",
    linkTo: GLOSSARY
  },
  {
    linkName: "News",
    linkTo: PRESS
  },
  {
    linkName: "FAQs",
    linkTo: FAQ
  },
];



export const renderResourceLinks = (resourceObj, light ) => (
  <div className={`${styles.resourceLink} ${light && styles.light} `}>
    <h3>RESOURCE LINKS</h3>
    <div className={styles.links}>
      {resourceObj.map(({ linkName, linkTo, imgSrc, imgClassName }) => (
        <Button2 tertiary dark={light ? false : true} light={light ? true : false} className={styles.resourceInnerLink} href={`${linkTo}`} target="_self" rel="noreferrer"><p>{linkName}</p><IconChevron /></Button2>
      ))}
    </div>
  </div>
);

const renderList = (e, i, handleSearchTags) => {
  return <ArticleCard light list={e} key={i} marginTop fromArticle />;
};

export const RenderTotalList = ({
    loader,
    allList,
    handleChange,
    total,
    totalPage,
    handleSearchTags,
  }) => (
    <>
      {!loader ? (
        allList?.length !== 0 && (
          <div
            className={`${styles.gridContainer} ${
              total < 9 && styles.marginBottom
            }`}
          >
            {allList?.map((list, i) => (
              <motion.div className={`${styles.articleContainer}`} key={i} {...bounceIn}>
                {renderList(list, i, handleSearchTags)}
              </motion.div>
            ))}
          </div>
        )
      ) : (
        <div >
          <FontAwesomeIcon icon={faCircleNotch} className="spin" />
        </div>
      )}
      {allList?.length === 0 && (
        <div className={styles.noData} >
          <h3>We're sorry, but we can't find any articles under that search. Please try again later.</h3>
          <Button2 light to={KNOWLEDGECENTER} className={styles.backToKnowledgeCenter}><p>Return to Knowledge Center</p><IconChevron /></Button2>
        </div>
      )}
      {total > 9 && (
        <div
          className={`${styles.articlePagination}`}
        >
          <Stack alignItems="center">
            <Pagination
              className={styles.pagination}
              count={totalPage}
              size="small"
              onChange={handleChange}
              activeClass={styles.active}
              renderItem={(item) => (
                <PaginationItem
                  onClick={ScrollToTop()}
                  slots={{
                    previous: IconChevronDown,
                    next: IconChevronUp,
                  }}
                  {...item}
                />
              )}
            />
          </Stack>
        </div>
      )}
    </>
);
  