import React, { Component } from "react";
import styles from "./Dropzone.module.scss";
import { ReactComponent as DropFileLogo } from "../../assets/SVGs/drop-file-logo.svg";
import { ReactComponent as UploadLogo } from "../../assets/SVGs/upload-tray.svg";
import Button2 from "../Button2";
import { VALID_FILE_TYPE } from "./constant";

const list = VALID_FILE_TYPE;
const dotList = list.map((item) => `.${item}`);
const validTypeString = dotList.join(", ");

class Dropzone extends Component {
  constructor(props) {
    super(props);
    this.state = { highlight: false };
    this.fileInputRef = React.createRef();
  }

  openFileDialog = () => {
    if (this.props.disabled) return;
    this.fileInputRef.current.click();
  };

  fileListToArray = (list) => {
    const array = [];
    for (let i = 0; i < list.length; i++) {
      array.push(list.item(i));
    }
    return array;
  };

  onFilesAdded = (evt) => {
    if (this.props.disabled) return;
    const files = evt.target.files;
    const array = this.fileListToArray(files);
    this.props.onFilesAdded(array);
    this.fileInputRef.current.value = "";
  };

  onDragOver = (evt) => {
    evt.preventDefault();
    if (this.props.disabled) return;

    this.setState({ highlight: true });
  };

  onDragLeave = () => {
    this.setState({ highlight: false });
  };

  onDrop = (event) => {
    event.preventDefault();

    if (this.props.disabled) return;

    const files = event.dataTransfer.files;

    const array = this.fileListToArray(files);
    this.props.onFilesAdded(array);

    this.setState({ highlight: false });
  };

  render() {
    return (
      <>
        {this.props.disabled ? (
          <div className={`${styles.loadingOverlay} `} title={""} />
        ):(
          <>
            {this.props.link ? (
              <div className={`
                ${styles.linkDropzone} 
                ${this.props.disabled ? styles.cursorDefault : styles.cursorPointer} 
              `} onClick={this.openFileDialog} >
                <input
                  ref={this.fileInputRef}
                  className={styles.fileInput}
                  type="file"
                  multiple
                  onChange={this.onFilesAdded}
                  accept={validTypeString}
                />
                {this.props.description ? (<p className={styles.description}>{this.props.description}</p>) : null}
                <Button2 dark linkButton xs className={styles.link}><p>Upload Them Now</p><UploadLogo /></Button2>
              </div>
            ) : (
              <div
                className={`${styles.dropzone} ${
                  this.props.disabled ? styles.cursorDefault : styles.cursorPointer
                } ${this.state.highlight ? styles.dropHighlight : ""}`}
                onClick={this.openFileDialog}
                onDragOver={this.onDragOver}
                onDragLeave={this.onDragLeave}
                onDrop={this.onDrop}
              >
                <DropFileLogo className={styles.icon} />
                <input
                  ref={this.fileInputRef}
                  className={styles.fileInput}
                  type="file"
                  multiple
                  onChange={this.onFilesAdded}
                  accept={validTypeString}
                />
                <span>
                  <span className={styles.dragText}>Drag and drop or</span>
                  <br />
                  <span className={styles.highlight}>Browse your files</span>
                </span>
                
              </div>
            )}
          </>
        )}
      </>
    );
  }
}

export default Dropzone;
