import React from "react";
import "./Divider.scss"
import variables from "../../mixins.scss";

function Divider(props) {

	const styles = {
		...props.style || {},
		height: props.height ? props.height : props.vertical ? '100%' : props.horizontal ? '1px' : '1px',
        width: props.width ? props.width : props.vertical ? '1px' : props.horizontal ? '100%' : '100%',
        backgroundColor: props.light ? `${variables.dividerGrey}` : props.dark ? `${variables.dividerColor}` : props.purple ? `${variables.blueVioletColor}` : props.accent ? `${variables.accentColor}` :`${variables.dividerGrey}`,
        borderRadius: props.round ? '2000px' : '0px'
	};

	return (
		<div id="divider" style={styles} className={props.className}>
		</div>
	)
}

export default Divider;