export const ERR = "error";
export const UPLOADED = "uploaded";
export const FULFILLED = "fulfilled";
export const PENDING = "pending";

export const VALID_FILE_TYPE = [
  "pdf",
  "xls",
  "xlsx",
  "csv",
  "tsv",
  "txt",
  "numbers",
];

export const MAX_SIZE_IN_MB = 100;
export const MAX_REPORTS_SIZE = 1024 * 1024 * MAX_SIZE_IN_MB;
