import React from "react";
import styles from "./CornerSection.module.scss";
import { ReactComponent as RightCorner } from "../../assets/SVGs/right-corner.svg";
import { ReactComponent as LeftCorner } from "../../assets/SVGs/left-corner.svg";

function CornerSection(props) {

  return (
    <div className={`${styles.cornerSection} ${props.className}`}>
        <LeftCorner className={styles.left} />
        <RightCorner className={styles.right} />
      {props.children || "EMPTY"}
    </div>
  );
}

export default CornerSection;