import {
  HOME,
  AGENT_HOME,
  OUR_MISSION,
  PARTNERS,
  PRESS,
  WHY,
  COMPETITOR,
  KNOWLEDGECENTER,
  VIDEOS,
  ARTIST_SEARCH_ESTIMATES,
  ARTIST_ENROLLMENT,
  FUNDING_NETWORK,
  LOGIN,
  GLOSSARY,
  FAQ,
  LABELHOME,
  ADVANCING_INDEPENDENCE,
  ARTIST_LIKE_YOU,
  LEARN_MORE,
  TERM_OF_USE,
  ARTIST_STORIES,
  PUBLISHING,
  PARTNER_DIRECTORY,
  BID,
  SINGLE_PAGE_SIGNUP,
  A2IMHOME,
  DEAL_COMPARISON,
  DEAL_COMPARISON_FORM,
  OUR_TEAM,
  DEAL_STRUCTURES,
  CONTRACT_ANALYSIS,
} from "../../routes/constants";

const metaJSON = {
  [HOME]: {
    title: "Home | beatBread",
    description: "more Choices. better Funding. beatBread empowers artists and labels with a choice of funding options and control of their own business.",
    indexed: true,
  },
  [AGENT_HOME]: {
    title: "Home | beatBread",
    description: "more Choices. better Funding. beatBread empowers artists and labels with a choice of funding options and control of their own business.",
    indexed: true,
  },
  [OUR_MISSION]: {
    title: "Our Mission | beatBread",
    description: "beatBread’s mission is to empower artists so that they can own their art and control their careers. We give more artists access to funding and more choice to select the promotion, marketing, and production partners that best fit their unique needs.",
    indexed: true,
  },
  [OUR_TEAM]: {
    title: "Our Advisors | beatBread",
    description: "beatBread has an amazing team of advisors comprized of talented individuals from all across the music space.",
    indexed: true,
  },
  [PARTNERS]: {
    title: "Partners | beatBread",
    description:
      "At beatBread, our partners can get capital to fund artist advances, powerful underwriting tools, and much more!",
    indexed: true,
  },
  [PRESS]: {
    title: "News | beatBread",
    description:
      "Read the latest news about beatBread and how we are changing the music industry",
    indexed: true,
  },
  [WHY]: {
    title: "How it Works | beatBread",
    description: "Learn how beatBread's funding process works",
    indexed: true,
  },
  [COMPETITOR]: {
    title: "Funding Alternatives | beatBread",
    description: "Learn how beatBread compares to others in the artist funding industry.",
    indexed: false,
  },
  [KNOWLEDGECENTER]: {
    title: "Knowledge Center | beatBread",
    description: "Explore our Knowledge Center to learn how beatBread works, read articles with industry and career building insights, and hear from artists who have used our funding to keep control and stay independent.",
    indexed: false,
  },
  [VIDEOS]: {
    title: "Videos | beatBread",
    description: "funding Music. advancing Independence",
    indexed: false,
  },
  [ARTIST_SEARCH_ESTIMATES]: {
    title: "Artist Funding | beatBread",
    description: "Search for an artist and see advance estimates in seconds.",
    indexed: false,
  },
  [ARTIST_ENROLLMENT]: {
    title: "Artist Funding | beatBread",
    description: "Search for an artist and see advance estimates in seconds.",
    indexed: false,
  },
  [FUNDING_NETWORK]: {
    title: "Funding Network | beatBread",
    description: "Compare offers from multiple distributors and music companies.  Choose the option best fit for you.",
    indexed: true,
  },
  [DEAL_COMPARISON]: {
    title: "Compare Offers | beatBread",
    description: "Share your music financing offers and beatBread will break them down, compare them to deals inside and outside our platform, and help you make informed decisions.",
    indexed: true,
  },
  [DEAL_COMPARISON_FORM]: {
    title: "Compare Offers | beatBread",
    description: "Share your music financing offers and beatBread will break them down, compare them to deals inside and outside our platform, and help you make informed decisions.",
    indexed: true,
  },
  [LOGIN]: {
    title: "Login | beatBread",
    description: "Log in to your beatBread account",
    indexed: true,
  },
  [GLOSSARY]: {
    title: "Glossary | beatBread",
    description: "Get definitions for the terms used throughout beatBread's funding process",
    indexed: true,
  },
  [FAQ]: {
    title: "FAQ | beatBread",
    description: "Get answers to frequently asked questions about beatBread",
    indexed: true,
  },
  [LABELHOME]: {
    title: "Label & Distributor Funding | beatBread",
    description: "beatBread funds independent labels and distributors because we know that great partners are essential to advancing an artist’s career. Our funding gives independent music executives the flexibility and financial firepower to grow their rosters, fund new projects, expand their operations, and even buy out of bad deals to leverage better terms with finance and service partners. ",
    indexed: true,
  },
  [ADVANCING_INDEPENDENCE]: {
    title: "Advancing Independence | beatBread",
    description: "Article insights into beatBread and the Modern Music Industry",
    indexed: true,
  },
  [ARTIST_LIKE_YOU]: {
    title: "Artists Like You | beatBread",
    description: "Hear about artists who have used beatBread to fund their music",
    indexed: true,
  },
  [LEARN_MORE]: {
    title: "Learn More | beatBread",
    description: "Learn more about beatBread though Q&A and more",
    indexed: true,
  },
  [TERM_OF_USE]: {
    title: "Terms of Use | beatBread",
    description: "Read the terms of use for beatBread",
    indexed: true,
  },
  [ARTIST_STORIES]: {
    title: "Artist Testimonials | beatBread",
    description: "Hundreds of Artists Have Stayed Independent with beatBread. Hear about artists who have used beatBread to fund their music",
    indexed: true,
  },
  [PUBLISHING]: {
    title: "Publishing | beatBread",
    description: "Access your publishing revenue with beatBread",
    indexed: true,
  },
  [PARTNER_DIRECTORY]: {
    title: "Partner Directory | beatBread",
    description: "beatBread has a network of distribution partners that can help you get the best terms",
    indexed: true,
  },
  [BID]: {
    title: "Bid Guidance | beatBread",
    description: "Bid on an artist through beatBread's new service tool",
    indexed: false,
  },
  [SINGLE_PAGE_SIGNUP]: {
    title: "Sign Up | beatBread",
    description: "Sign up here to access all the funding options beatBread has to offer. Whether you are an artist or a label, we have options that are sure to give you the funding you need.",
    indexed: true,
  },
  [A2IMHOME]: {
    title: "A2IM Label Funding | beatBread",
    description: "Learn about our customizable funding options for labels through our A2IM Partnershp.",
    indexed: true,
  },
  [DEAL_STRUCTURES]: {
    title: "Deal Structures | beatBread",
    description: "Learn about the deal structures that beatBread offers.",
    indexed: false,
  },
  [CONTRACT_ANALYSIS]: {
    title: "Contract Analysis | beatBread",
    description: "Upload your offer and ask anything about the terms.",
    indexed: false,
  },
};

export default metaJSON;
