import React, { useEffect, useRef } from "react";
import "./Shell.scss";
import { Outlet, useLocation } from "react-router-dom";
import { HeaderV3 } from "../../components";
import {
  AGENT_HOME,
  ARTIST_STORIES,
  BID,
  PRIVACY_POLICY,
  TERM_OF_USE,
  WHY,
} from "../../routes/constants";
import { get } from "lodash";
import { FooterV3, FooterV3Snap } from "../../sections";

const NO_NAVBAR = [AGENT_HOME];
const NO_FOOTER = [];
const NO_SNAP = [BID, PRIVACY_POLICY, TERM_OF_USE, ARTIST_STORIES, WHY];

function Shell(props) {

  const location = useLocation();
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTo(0, 0);
    }
  }, [location]);

  const renderNavbar = () => {
    if (props.noNavbar || NO_NAVBAR.includes(props.currentLocation)) {
      return <></>;
    } else {
      return (
        <HeaderV3
          home={get(props, "home")}
          currentLocation={get(props, "currentLocation")}
        />
      );
    }
  };

  const renderFooter = () => {
    if (NO_FOOTER.includes(props.currentLocation)) {
      return null;
    } else if (NO_SNAP.includes(props.currentLocation)) {
      return <FooterV3 />;
    } else {
      return <FooterV3Snap />;
    }
  };

  return (
    <div id="snap" ref={containerRef} className={`shell ${NO_SNAP.includes(props.currentLocation) ? "" : "snap" }`}>
      {renderNavbar()}
      <Outlet />
      {renderFooter()}
    </div>
  );
}

export default Shell;
