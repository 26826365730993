import React from "react";
import styles from "./TextArea.module.scss";


function TextArea (props) {
	return (
		<div className={styles.textAreaContainer}>
			<div className={styles.labelContainer}>
				<p style={{display:props.includeTitle}}>{props.title || ""}</p>
				{props.required ? (
				<></>
				) : (
				<div className={styles.optional}>(Optional)</div>
				)}
			</div>
			<textarea 
				className={styles.textArea} 
				placeholder={props.placeholder || ""} 
				value={props.value || ""} 
				required={props.required || false} 
				onChange={(e) => props.onChange(props.keyName, e)}
			/>
		</div>
	)
}

export default TextArea;