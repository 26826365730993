import React from "react";
import styles from "./KnowledgeHeader.module.scss";
import { motion } from "motion/react";
import { renderResourceLinks } from "../../pages/KnowledgeCenter/helper";
import { ReactComponent as RightCorner } from "../../assets/SVGs/right-corner.svg";



const KnowledgeHeader = (props) => {
    const rightMotion = {
        initial: {right: "-120px", transition: { duration: 0.3, ease: "easeInOut", delay: 0.2 }}, whileInView: {right: "0px", transition: { duration: 0.3, ease: "easeInOut", delay: 0.2 }}
      };
  return (
    <motion.div initial="initial" whileInView="whileInView" animate="initial" id={`${props.id}`} className={`${styles.snapSection} ${styles.bgImageCtn} ${ props.dark ? styles.dark : styles.light } ${styles.short}`}>
        {props.dark ? (null):(<motion.div  className={styles.right} variants={rightMotion}><RightCorner  /></motion.div>)}
    
            <div className={styles.landingCtn}>
              <div className={styles.headingCtn}>
                <div className={styles.titleContainer}>
                    {props.primary ? (<span className={styles.primary}>{props.primary}</span>):(null)}
                    {props.secondary ? (<span className={styles.secondary}>{props.secondary}</span>):(null)}
                </div>
                  {props.subHeading ? (<p className={styles.subHeading}>{props.subHeading}</p>):(null)}
              </div>
              {props.resourceLinks ? (renderResourceLinks(props.resourceLinks, props.light )):(null)}
            </div>
    </motion.div>
  );
};

export default KnowledgeHeader;
