import React, { Component } from "react";
import "./FAQs.scss";
import { GlossaryBox, CustomHTML } from "../../components";
import { GetIframeVideo } from "../../routes/constants";
import axios from "axios";
import { Modal } from "@mui/material";
import styles from "../../pages/Glossary/Glossary.module.scss";
import { ReactComponent as CrossIcon } from "../../assets/SVGs/x-close.svg";
import { getQuesAns } from "../../pages/Glossary/helper";

class FAQs extends Component {
  constructor() {
    super();
    this.state = {
      list: "",
      loader: false,
      isOpen: false,
    };
  }

  componentDidMount() {
    this.getData();
  }
  getData = () => {
    this.setState({ loader: true });
    axios
      .get(`${process.env.REACT_APP_API_URL}/user/faqs?type=faqs`)
      .then((res) => {
        this.setState({
          list: res.data.data,
        });
        this.setState({ loader: false });
      })
      .catch((err) => {
        this.setState({ loader: false });
      });
  };
  handleModal = (link) => this.setState({ isOpen: true, link });

  closeModal = () => this.setState({ isOpen: false, link: null });

  renderModal = () => (
    <Modal
      open={this.state.isOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={this.closeModal}
    >
      <>
        <div className={styles.overlayClass}>
          <div className={styles.iframeContainer}>
            <GetIframeVideo
              className={styles.iframe}
              title="YouTube video player"
              url={this.state.link}
              loading="lazy"
            />
          </div>
          <div className={styles.icon} onClick={this.closeModal}><CrossIcon /></div>
        </div>
      </>
    </Modal>
  );
  render() {
    return (
      <div id="faqs" className="faqs">
        <div className="faq-list">
          {getQuesAns(this.state.list).map((e, i) => (
            <GlossaryBox
            light={this.props.dark ? false : this.props.light ? true : true }
            key={i}
            term={<CustomHTML html={e.question} />}
            video={e.video}
            link={e.link}
            handleModal={this.handleModal}
          >
            <CustomHTML html={e.answer} />
          </GlossaryBox>
          ))}
        </div>
        {this.renderModal()}
      </div>
    );
  }
}

export default FAQs;
