const ROW_REGEX = /<tr>(.*?)<\/tr>/gs;
const DATA_REGEX = /<td.*?>(.*?)<\/td>/gs;
const P_REGEX = /<p.*?>(.*?)<\/p>/s;

export const getInnerData = (str = []) => {
  const span = document.createElement("div");
  span.innerHTML = str && str[0];
  return span.textContent;
};

export const getOuterData = (str = []) => {
  const span = document.createElement("div");
  span.innerHTML = str && str[0];
  return span.outerHTML;
};

export const getQuesAns = (str = "") => {
  const arr = [];
  const row = str.match(ROW_REGEX);
  if (row && row.length > 0) {
    for (let i = 0; i < row.length; i++) {
      const obj = {};
      const innerRow = row[i].match(DATA_REGEX);
      obj.question = getInnerData(innerRow[0]?.match(P_REGEX));
      obj.answer = getOuterData(innerRow[1]?.match(DATA_REGEX));
      obj.link = getInnerData(innerRow[2]?.match(P_REGEX));
      obj.video = getInnerData(innerRow[3]?.match(P_REGEX));
      arr.push(obj);
    }
  }
  return arr.slice(1);
};
