import React, { Component } from "react";
import styles from "./HeaderV3.module.scss";
import { Transition } from "react-transition-group";
import { ReactComponent as BoxClosed } from "../../assets/SVGs/chevron-down-white.svg";
import { ReactComponent as BoxOpened } from "../../assets/SVGs/chevron-up-white.svg";
import { Link } from "react-router-dom";
import onClickOutside from "react-onclickoutside";

const duration = 0.25;

const defaultStyle = {
  display: "none",
};

const transitionStyles = {
  entering: { display: "flex" },
  entered: { display: "flex" },
  exiting: { display: "none" },
  exited: { display: "none" },
};

class NavDrawer extends Component {
  constructor() {
    super();
    this.state = {
      open: false,
    };
  }

  handleClickOutside = evt => {
    this.setState({ open: false });
  };

  toggleBox = () => {
    this.setState({ open: !this.state.open });
  };
  handleClick = () => {
    this.setState({ open: false });
    this.props.onUpdateState(false);
  };

  render() {
    return (
      <>
        <div className={`${styles.drawerContainer} ${this.state.open ? styles.open : styles.closed}`} >
            <div ref={this.headerRef} className={`${styles.title} ${this.state.open ? styles.open : styles.closed}`} onClick={this.toggleBox}>
                <p>{this.props.title}</p>
                {this.state.open ? <BoxOpened className={styles.arrow}/> : <BoxClosed className={styles.arrow}/>}
            </div>
            <Transition in={this.state.open} timeout={duration}>
                {(tState) => (
                <div
                    ref={this.definitionRef}
                    className={`${styles.drawer} ${this.state.open ? styles.open : styles.closed}`}
                    style={{
                    ...defaultStyle,
                    ...transitionStyles[tState],
                    }}
                >
                    {this.props.items.map((item, i) => (
                        <Link
                            to={item.to}
                            key={i}
                            className={`${styles.navLink}`}
                            onClick={this.handleClick}
                        >
                            <p>{item.title}</p>
                        </Link>
                    ))}
                </div>
                )}
            </Transition>
        </div>
      </>
    );
  }
}
export default onClickOutside(NavDrawer);
