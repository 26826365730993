import React from "react";
import styles from "./Input3.module.scss";

function Input3(props) {
  const disabled = props.disabled || false;
  return (
    <>
      {props.input === "radio" || props.input === "checklist" ? (
        <div className={`${styles.input4} ${styles.radio}`}>
          <div className={styles.labelContainer}>
            <label htmlFor={props.keyName} className={styles.title}>{props.label || props.title}</label>
            <div className={styles.optional} style={{display: (props.required ? "none" : "flex")}}>(Optional)</div>
          </div>
          <div className={styles.captionContainer}>
            <div className={styles.checkList}>
              {Object.entries(props.boxes).map((e, i) => {
                return (
                  <div key={i} className={`${styles.clOption} ${props.full ? styles.full : ""}`}>
                    <input
                      className={styles.checkListInput}
                      type="radio"
                      name={props.keyName}
                      checked={e[1]}
                      onChange={() =>
                        props.onChange(props.keyName, e[1], "radio", e[0])
                      }
                      disabled={disabled}
                    />
                    <div className={styles.label}>{e[0]}</div>
                  </div>
                );
              })}
            </div>
            <p className={styles.caption} style={{display: (props.caption ? "flex" : "none")}}>{props.caption}</p>
          </div>
        </div>
      ) : props.input === "boxes" ? (
        <div className={`${styles.input4} ${styles.boxes}`}>
          <div className={styles.labelContainer}>
            <label htmlFor={props.keyName} className={styles.title}>{props.label || props.title}</label>
            <div className={styles.optional} style={{display: (props.required ? "none" : "flex")}}>(Optional)</div>
          </div>
          <div className={styles.captionContainer}>
            <div className={`${styles.boxList} ${props.full ? styles.full : ""}`}>
              {Object.entries(props.boxes).map((e, i) => {
                return (
                  <div key={i} className={`${styles.clOption}`}>
                    <input
                      className={styles.checkListInput}
                      id={"checkbox" + i}
                      type="radio"
                      name={props.keyName}
                      checked={e[1]}
                      onChange={() =>
                        props.onChange(props.keyName, e[1], "checklist", e[0])
                      }
                      disabled={disabled}
                    />
                    <label htmlFor={"checkbox" + i} className={`${props.md ? styles.md : styles.sm}`}>{e[0]}</label>
                  </div>
                );
              })}
            </div>
          <p className={styles.caption} style={{display: (props.caption ? "flex" : "none")}}>{props.caption}</p>
          </div>
        </div>
      ) : props.input === "largeBoxes" ? (
        <div className={`${styles.input4} ${styles.largeBoxes}`}>
          <label htmlFor={props.keyName} className={styles.boxLabel}>{props.label || props.title}</label>
          <div className={styles.largeBoxList}>
            {Object.entries(props.boxes).map((e, i) => {
              return (
                <div key={i} className={`${styles.clOption} ${props.full ? styles.full : ""}`}>
                  <input
                    className={styles.checkListInput}
                    id={"checkbox" + i}
                    type="radio"
                    name={props.keyName}
                    checked={e[1]}
                    onChange={() =>
                      props.onChange(props.keyName, e[1], "checklist", e[0])
                    }
                    disabled={disabled}
                  />
                  <label htmlFor={"checkbox" + i}>
                    {props.labels[i].title}
                    <div className={styles.description} style={{display: (props.labels[i].description ? "flex" : "none")}}>{props.labels[i].description}</div>
                  </label>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div className={styles.input4}>
          <div className={styles.labelContainer}>
            <label htmlFor={props.keyName} className={styles.title}>{props.label || props.title}</label>
            <div className={styles.optional} style={{display: (props.required ? "none" : "flex")}}>(Optional)</div>
          </div>
          <div className={styles.captionContainer}>
            <input
              className={styles.input}
              type={props.type || "text"}
              placeholder={props.placeholder || ""}
              autoComplete={props.autocomplete || ""}
              value={props.value || ""}
              onChange={(e) => props.onChange(props.keyName, e, "input")}
              disabled={disabled}
            />
            <p className={styles.caption} style={{display: (props.caption ? "flex" : "none")}}>{props.caption}</p>
          </div>
        </div>
      )}
    </>
  );
}

export default Input3;
