import { Link } from "react-router-dom";
import styles from "./Article.module.scss";
import { KNOWLEDGECENTER } from "../../routes/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import { ReactComponent as PreviousArrow } from "../../assets/SVGs/previousArrow.svg";
import { ReactComponent as NextArrow } from "../../assets/SVGs/nextArrow.svg";
import BackgroundImage from "../../assets/SVGs/bullet.svg";
//import styles from "./../AdvancingIndependence/AdvancingIndependence.module.scss";
import { ReactComponent as IconChevron } from "../../assets/SVGs/double-chevron-right.svg";
import { Button2 } from "../../components";

const renderTags = (list) => {
  return (
    list &&
    list.tag &&
    list?.tag?.length &&
    list.tag.map((tag, i) => (
      <>
        <Link
          className={styles.link}
          key={i}
          to={`${KNOWLEDGECENTER}/${encodeURIComponent(tag)}`}
        >
          {tag}
        </Link>
        <div className={styles.linkComma}>{list?.tag?.length !== i + 1 ? "," : ""}</div>
      </>
    ))
  );
};

export const ArticleListing = ({
  loader,
  articleList = [],
  total,
  limit = 3,
  totalPage,
  handlePageChange,
  marginTop,
}) => (
  <div className={styles.mainArticleDiv}>
    <div className={styles.wrapper}>
      {!loader &&
        (articleList?.length !== 0 ? (
          <div
            className={`${styles.gridContainer} ${
              total < limit && styles.marginBottom
            }`}
          >
            {articleList?.map((list, i) => (
              <ArticleCard list={list} key={i} marginTop={marginTop} />
            ))}
          </div>
        ) : (
          <div className={styles.noData}>
            <p>No Articles</p>
          </div>
        ))}
      {loader && (
        <div className={styles.noData}>
          <FontAwesomeIcon icon={faCircleNotch} className="spin" />
        </div>
      )}
    </div>
    {total > limit && (
      <div className={`${styles.articlePagination} ${styles.extraPadding}`}>
        <Stack alignItems="center">
          <Pagination
            className={styles.pagination}
            count={totalPage}
            size="small"
            onChange={handlePageChange}
            activeClass={styles.active}
            sx={{
              "& .MuiPaginationItem-page": { color: "transparent" },
              "& .Mui-selected": {
                backgroundImage: `url(${BackgroundImage})`,
                backgroundColor: "transparent",
              },
            }}
            renderItem={(item) => (
              <PaginationItem
                slots={{
                  previous: PreviousArrow,
                  next: NextArrow,
                }}
                {...item}
              />
            )}
          />
        </Stack>
      </div>
    )}
  </div>
);

export const RenderArticleCarousel = ({
  category,
  loader,
  articleList = [],
  total,
  limit = 3,
  totalPage,
  handlePageChange,
}) => (
  <div className={styles.articleDiv}>
    <div className={styles.mainDiv}>
      <ArticleListing
        limit={limit}
        loader={loader}
        articleList={articleList}
        total={total}
        totalPage={totalPage}
        handlePageChange={handlePageChange}
      />
    </div>
    <Link className={styles.footerDiv} to={KNOWLEDGECENTER}>
      VIEW ALL »
    </Link>
  </div>
);

export const ArticleCard = ({ list, marginTop, light }) => (
  <>
    {list.category !== "Press" ? ( 
      <div className={`${styles.articleContainer} ${marginTop && styles.marginTop} ${light ? styles.light : styles.dark}`}>
        <div className={`${styles.noStylelink} ${styles.articleTop}`}>
          <Link
            to={`${KNOWLEDGECENTER+"/"+list.path}`}
            onClick={() => {
              sessionStorage.setItem(
                "listData",
                JSON.stringify({
                  id: list._id,
                  category: list.category,
                })
              );
            }}
            className={`${styles.noStylelink} ${styles.articleTop}`}
          >
            <h3 className={styles.category}>{list.category}</h3>
            {list.image ? (
                <div className={`${styles.imgContainer}`}>
                  <img src={list.image} alt="" />
                </div>
            ) : ( null )}
            <div className={styles.titleContainer}>
              <h4 className={styles.title}>{list.title}</h4>
              <p className={`${styles.author}`}> {list.author} </p>
            </div>
            <p className={styles.desc}>
              {list.description?.length > 135
                ? list.description + "..."
                : list.description}
            </p>
            </Link>
          {list?.tag?.length >= 1 ? (
            <div className={styles.tagsLinks}>
              <p className={`${styles.tags}`}>
                Tags:
              </p>
              {renderTags(list)}
            </div>
          ): (null)} 
        </div>
        <Button2 tertiary to={`${KNOWLEDGECENTER+"/"+list.path}`}
          onClick={() => {
            sessionStorage.setItem(
              "listData",
              JSON.stringify({
                id: list._id,
                category: list.category,
              })
            );
          }}
          className={styles.articleLink}
          light={light ? true : false}
          dark={light ? false : true}
        >
          <p>READ MORE</p><IconChevron />
        </Button2>
      </div>
    ) : (
      <div className={`${styles.articleContainer} ${marginTop && styles.marginTop} ${light ? styles.light : styles.dark}`}>
        <div className={`${styles.noStylelink} ${styles.articleTop}`}>
        <a href={list.sourceUrl} target="_blank" rel="noreferrer" className={`${styles.noStylelink} ${styles.articleTop}`}>
          <h3 className={styles.category}>{list.category}</h3>
          {list.image ? (
              <div className={`${styles.imgContainer}`}>
                <img src={list.image} alt="" />
              </div>
          ) : ( null )}
          <div className={styles.titleContainer}>
            <h4 className={styles.title}>{list.title}</h4>
            <p className={`${styles.author}`}> {list.author} </p>
          </div>
          <p className={styles.desc}>
            {list.description?.length > 135
              ? list.description + "..."
              : list.description}
          </p>
          </a>
        {list?.tag?.length >= 1 ? (
          <p className={styles.tagsLinks}>
            <span className={`${styles.tags}`}>
              Tags:
            </span>
            {renderTags(list)}
          </p>
        ): (null)} 
        </div>
        <Button2 tertiary md
          href={list.sourceUrl} target="_blank" rel="noreferrer" 
          className={styles.articleLink}
          light={light ? true : false}
          dark={light ? false : true}
        >
          <p>READ MORE</p><IconChevron />
        </Button2>
      </div>
    )}
  </>
);

export const ArtistCard = ({ list, marginTop, light }) => (
  <>
      <div className={`${styles.articleContainer} ${marginTop && styles.marginTop} ${light ? styles.light : styles.dark}`}>
        <Link
          to={`${KNOWLEDGECENTER+"/"+list.path}`}
          onClick={() => {
            sessionStorage.setItem(
              "listData",
              JSON.stringify({
                id: list._id,
                category: list.category,
              })
            );
          }}
          className={`${styles.noStylelink} ${styles.articleTop}`}
        >
          {list.image ? (
              <div className={`${styles.imgContainer}`}>
                <img src={list.image} alt="" />
              </div>
          ) : ( null )}
          <div className={styles.titleContainer}>
            <h4 className={styles.title}>{list.title}</h4>
          </div>
          <p className={styles.desc}>
            {list.description?.length > 135
              ? list.description + "..."
              : list.description}
          </p>
        </Link>
        <Button2 tertiary md to={`${KNOWLEDGECENTER+"/"+list.path}`}
          onClick={() => {
            sessionStorage.setItem(
              "listData",
              JSON.stringify({
                id: list._id,
                category: list.category,
              })
            );
          }}
          className={styles.articleLink}
          light={light ? true : false}
          dark={light ? false : true}
        >
          <p>READ MORE</p> <IconChevron />
        </Button2>
      </div>
  </>
);