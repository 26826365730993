import {
  OUR_MISSION,
  OUR_TEAM,
  ARTIST_SEARCH_ESTIMATES,
  FAQ,
  KNOWLEDGECENTER,
  LABELHOME,
  PARTNERS,
  PRESS,
  GLOSSARY,
} from "../../routes/constants";

export const DRAWER_NAVIGATION = [
  {
    title: "Funding",
    options: [
      { title: "Artists", to: ARTIST_SEARCH_ESTIMATES },
      { title: "Labels and Distributors", to: LABELHOME },
    ],
  },
  {
    title: "About Us",
    options: [
      { title: "Our Mission", to: OUR_MISSION },
      { title: "Our Advisors", to: OUR_TEAM },
      { title: "Partners", to: PARTNERS },
      { title: "News", to: PRESS },
    ],
  },
  {
    title: "Resources",
    options: [
      { title: "Knowledge Center", to: KNOWLEDGECENTER },
      { title: "FAQs", to: FAQ },
      { title: "Glossary", to: GLOSSARY},
    ],
  },
];
