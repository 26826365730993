import React from "react";
import styles from "./StepCardV2.module.scss";
import Divider from "../Divider";

function StepCardV2(props) {
  return (
    <>
      {props.simplified ? (
        <div className={`${styles.step} ${props.light ? styles.light : ""}`}>
          <div className={styles.stepWord}>STEP</div>
          <div className={styles.stepNumber}>0{(props.step+1)}</div>
          <div className={styles.stepContent}>
            <Divider horizontal purple className={styles.stepDivider} />
            <h3 className={styles.stepTitle}>{props.title}</h3>
            {props.text ? (
              <p className={styles.stepText}>{props.text}</p>
            ) : null}
          </div>
        </div>
      ) : (
        <div className={`${styles.step} ${props.light ? styles.light : ""}`}>
          <div className={styles.stepWord}>STEP</div>
          <div className={styles.stepNumber} >0{(props.step+1)}</div>
          <div className={styles.stepContent}>
            <h3 className={styles.stepTitle}>{props.title}</h3>
            <Divider horizontal purple className={styles.stepDivider} />
            {props.text ? (
              <p className={styles.stepText}>{props.text}</p>
            ) : null}
          </div>
        </div>
    )}
    </>
  );
}

export default StepCardV2;
