import React from "react";
import metaJSON from "./constants";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { AGENT_HOME, AGENT_REGEX } from "../../routes/constants";
import { get } from "lodash";
import LargeImage from "../../assets/SVGs/logos/summary-large-image.webp";

function HelmetR(props) {
  const location = useLocation();
  const pathname = location.pathname.match("/.*/$")
    ? location.pathname.replace(/\/$/, "")
    : location.pathname;
  const mJSON = metaJSON[AGENT_REGEX.test(pathname) ? AGENT_HOME : pathname];
  const title = get(mJSON, "title");
  const description = get(mJSON, "description");
  const propsTitle = get(props.data, "title");
  const propsDescription = get(props.data, "body");
  const propsPathname = get(props.data, "sourceUrl");
  const propsImage = get(props.data, "image");

  return !props.data ? (
    <Helmet>
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta name="description" content={description} />

      {/* Open Graph tags for social media */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={LargeImage} />
      <meta property="og:url" content={pathname} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="beatBread"/>

      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image"/>
      <meta name="twitter:image" content={LargeImage}/>
      <meta name="twitter:image:alt" content="The beatBread logo in white on a fuzzy black gradient background."/>
      <meta name="twitter:site" content="@beatBreadfunds"/>
    </Helmet>
  ) : (
    <Helmet>
      <title>{propsTitle}</title>
      <meta name="title" content={propsTitle} />
      <meta name="description" content={propsDescription} />
      <link rel="canonical" href={propsPathname}></link>
      <meta name="robots" content="index, follow" />

      {/* Open Graph tags for social media */}
      <meta property="og:title" content={propsTitle} />
      <meta property="og:description" content={propsDescription} />
      <meta property="og:image" content={propsImage} />
      <meta property="og:url" content={propsPathname} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="beatBread"/>

      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image"/>
      <meta name="twitter:image" content={propsImage}/>
      <meta name="twitter:site" content="@beatBreadfunds"/>
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "http://schema.org",
          "@type": "Article",
          headline: propsTitle,
          datePublished: get(props.data, "publishDate"),
          author: {
            "@type": "Person",
            name: get(props.data, "author"),
          },
          image: propsImage,
        })}
      </script>
    </Helmet>
  );
}

export default HelmetR;
