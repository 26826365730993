import React from "react";
import styles from "./Spinner.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as BeatBreadLogo } from "../../assets/SVGs/logos/beatBreadLogoResponsive.svg";
import SnapSection from "../SnapSection";

const Spinner = ({ hideHeader }) => {
  return (
    <SnapSection>
    <div className={styles.container}>
      {!hideHeader && (
        <div className={styles.navbar}>
          <div className={styles.navbarLogo}>
              <BeatBreadLogo className={styles.logo} />
          </div>
        </div>
      )}
      <FontAwesomeIcon icon={faCircleNotch} className={styles.spin} />
    </div>
    </SnapSection>
  );
};

export default Spinner;
