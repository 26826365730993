import React, { Component } from "react";
import _, { get } from "lodash";
import Dropzone from "./Dropzone";
import styles from "./UploadFiles.module.scss";
import {
  ERR,
  UPLOADED,
  VALID_FILE_TYPE,
  MAX_REPORTS_SIZE,
  MAX_SIZE_IN_MB,
} from "./constant";
import { ReactComponent as TrashIcon } from "../../assets/SVGs/delete.svg";
import Progress from "./Progress";

class FileUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      uploadProgress: {},
      successUploadCount: 0,
      isRename: false,
      isOpen: false,
      isContactOpen: false,
      inputValue: "",
      isUpdated: false,
    };
    this.uploadRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    const current = this.uploadRef.current;
    current.scrollTop = current.clientHeight - current.scrollHeight;
  }

  onFilesAdded = (files) => {
    const fileList = files.filter(
      (file) => !this.state.uploadProgress[file.name]
    );
    const uploadProgress = this.state.uploadProgress;
    fileList.forEach((file) => {
      const fileExtension = file.name.split(".");
      if (file.size > MAX_REPORTS_SIZE) {
        uploadProgress[file.name] = {
          state: ERR,
          percentage: 100,
          msg: `File size exceeded (${MAX_SIZE_IN_MB}MB)`,
        };
      } else if (
        fileExtension.length < 2 ||
        VALID_FILE_TYPE.indexOf(fileExtension.pop().toLowerCase()) === -1
      ) {
        uploadProgress[file.name] = {
          state: ERR,
          percentage: 100,
          msg: "File type not accepted",
        };
      } else {
        uploadProgress[file.name] = {
          state: UPLOADED,
          percentage: 100,
        };
        this.props.updateFileList(file, false);
      }
    });
    this.setState((prevState) => ({
      uploadProgress: uploadProgress,
      files: prevState.files.concat(fileList),
    }));
  };

  deleteFile = (file) => {
    const { uploadProgress, files } = this.state;
    delete uploadProgress[file.name];
    _.remove(files, (f) => f.name === file.name);
    this.setState({
      uploadProgress,
      files,
    });
    this.props.updateFileList(file, true);
  };

  renderProgress = (file) => {
    const uploadProgress = this.state.uploadProgress[file.name];
    return (
      <div
        className={`${styles.progressWrapper}`}
      >
        <>
          <Progress
            progress={uploadProgress.percentage || 0}
            status={uploadProgress.state}
          />
        </>
      </div>
    );
  };

  renderFileList = () => (
    <div className={styles.upload}>
      <div className={styles.content}>
        <div className={styles.files}>
          {this.state.files.map((file, index) => {
            const uploadProgress = this.state.uploadProgress[file.name];
            return (
              uploadProgress && (
                <div
                  key={`${file.name}-${index}`}
                  className={`${styles.row} ${
                    uploadProgress.state === ERR ? styles.error : ""
                  }`}
                  title={file.name}
                >
                  <span className={styles.fileNameContainer}>
                    <span className={styles.fileName}>{file.name}</span>
                    {get(this.state.uploadProgress[file.name], "msg") && (
                      <span className={styles.msgError}>
                        {get(this.state.uploadProgress[file.name], "msg")}
                      </span>
                    )}
                    <span
                      className={`${styles.checkIcon} `}
                      onClick={() => {
                        this.deleteFile(file);
                      }}
                    >
                      <TrashIcon />
                    </span>
                  </span>
                  {this.renderProgress(file)}
                </div>
              )
            );
          })}
        </div>
      </div>
    </div>
  );


  render() {
    return (
      <div ref={this.uploadRef}
        className={`${styles.uploadContainer}`}
      >
        <Dropzone
          link={this.props.link ? true : false}
          description={this.props.linkDescription ? this.props.linkDescription : null}
          disabled={this.props.loading}
          onFilesAdded={this.onFilesAdded}
          successUploadCount={this.state.successUploadCount}
        />
        {this.renderFileList()}
      </div>
    );
  }
}

export default FileUpload;
