import React from "react";
import styles from "./Dropdown.module.scss";

function Dropdown(props) {

	return (
		<div className={styles.dropdown}>
			<div className={styles.labelContainer} style={{display: props.includeTitle}}>
				<label htmlFor={props.keyName}>{props.label || ""}</label>
				<div className={styles.optional} style={{display: (props.required ? "none" : "flex")}}>(Optional)</div>
			</div>
			<div className={styles.captionContainer}>
				<select name={props.keyName} required={props.required || false} className={styles.dropdownContainer} onChange={(e) => props.onChange(props.keyName, e, "dropdown", props.Label)}>
					<option value="" selected disabled>{props.placeholder || "Select an Option"}</option>
					{props.options.map((e,i) => 
					<option key={i} value={e}>{e}</option>
					)}
				</select>
				<p className={styles.caption} style={{display: (props.caption ? "flex" : "none")}}>{props.caption}</p>
			</div>
		</div>
	)
}

export default Dropdown;