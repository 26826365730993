import React, { Component } from "react";
import styles from "./GlossaryBox.module.scss";
import { Transition } from "react-transition-group";
import { ReactComponent as BoxClosed } from "../../assets/SVGs/plus.svg";
import { ReactComponent as BoxOpened } from "../../assets/SVGs/minus.svg";
import { ReactComponent as PlayIcon } from "../../assets/SVGs/play-icon.svg";
import { ReactComponent as DoubleArrowSvg } from "../../assets/SVGs/arrows-two.svg";
import Button2 from "../Button2";
import { motion } from "motion/react"
import bounceIn from "../Animations";

const duration = 0.25;

const defaultStyle = {
  opacity: 0,
  maxHeight: "0px",
  padding: "0px",
};

const transitionStyles = {
  entering: { display: 1, padding: "0 0px 20px 0px", maxHeight: "1000px" },
  entered: { opacity: 1, padding: "0 0px 20px 0px", maxHeight: "1000px" },
  exiting: { opacity: 0, padding: "0", maxHeight: "0px" },
  exited: { opacity: 0, padding: "0", maxHeight: "0px" },
};

class GlossaryBox extends Component {
  constructor() {
    super();
    this.state = {
      open: false,
    };
  }

  toggleBox = () => {
    this.setState({ open: !this.state.open });
  };

  renderLinkDiv = () => (
    <Button2 dark={this.props.light? false: true } light={this.props.light? true: false } tertiary className={styles.linkDiv} href={this.props.link} rel="noreferrer"><p>Read More</p> <DoubleArrowSvg /></Button2>
  );

  render() {
    return (
      <>
        <motion.div className={`${styles.glossBox} ${this.props.light ? styles.light : ""}`} {...bounceIn}>
          <div ref={this.headerRef} onClick={this.toggleBox} className={`${styles.gbHeader} ${styles.click}`}>
            <div
              className={`${styles.gbTerm} ${this.state.open ? styles.gbOpen : styles.gbClosed}`}
            >
              <span>{this.props.term}</span>
              {this.props.video && (
                <div
                  onClick={() => this.props.handleModal(this.props.video)}
                  className={styles.iconDiv}
                >
                  <PlayIcon />
                </div>
              )}
            </div>
            <div className={styles.gbArrow}>
              {this.state.open ? <BoxOpened /> : <BoxClosed />}
            </div>
          </div>
          <Transition in={this.state.open} timeout={duration}>
            {(tState) => (
              <div
                ref={this.definitionRef}
                className={styles.gbDefinition}
                style={{
                  ...defaultStyle,
                  ...transitionStyles[tState],
                }}
              >
                <div className={styles.click} onClick={this.toggleBox}>{this.props.children || this.props.definition}</div>
                {this.props.link && this.renderLinkDiv()}
              </div>
            )}
          </Transition>
        </motion.div>
      </>
    );
  }
}

export default GlossaryBox;
