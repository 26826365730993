import React from "react";
import styles from "./SnapSection.module.scss";
import { ReactComponent as RightCorner } from "../../assets/SVGs/right-corner.svg";
import { ReactComponent as LeftCorner } from "../../assets/SVGs/left-corner.svg";
import { motion } from "motion/react"

function SnapSection(props) {

  const leftMotion = {
    initial: {left: "-120px", transition: { duration: 0.3, ease: "easeInOut", delay: 0.2 }}, whileInView: {left: "0px", transition: { duration: 0.3, ease: "easeInOut", delay: 0.2 }}
  };
  const rightMotion = {
    initial: {right: "-120px", transition: { duration: 0.3, ease: "easeInOut", delay: 0.2 }}, whileInView: {right: "0px", transition: { duration: 0.3, ease: "easeInOut", delay: 0.2 }}
  };

  return (
    <motion.div initial="initial" whileInView="whileInView" animate="initial" id={`${props.id}`} className={`${styles.snapSection} ${props.className} ${props.light ? styles.light : ""} ${props.short ? styles.short : ""}`}>
      {props.children || "EMPTY"}
      {props.light && !props.hideArrows ? (<motion.div  className={styles.left} variants={leftMotion}><LeftCorner /></motion.div>) : ("") }
      {props.light && !props.hideArrows ? (<motion.div  className={styles.right} variants={rightMotion}><RightCorner  /></motion.div>) : ("") }
    </motion.div>
  );
}

export default SnapSection;
