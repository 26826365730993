import React, { Component } from "react";
import styles from "./FormSubmissionReplies.module.scss";
import { ReactComponent as ThanksIcon } from "../../assets/SVGs/checkmark-spiked.svg";
import { ReactComponent as IconChevron } from "../../assets/SVGs/double-chevron-right.svg";
import { ReactComponent as AlertIcon } from "../../assets/SVGs/Alert.svg";
import Button2 from "../Button2";
import { KNOWLEDGECENTER } from "../../routes/constants";
import variables from "../../mixins.scss";
import segment from "../../utils/segment";

class ThankYouSection extends Component {
    constructor() {
      super();
      this.state = {};
    }
  
    componentDidMount() {
      segment.track.artistFailed()
    }
  
    render() {
      return (
        <div className={styles.content}>
        <div className={styles.titleContainer}>
            <div className={styles.icon}>
                <svg id={styles.svgGradient} aria-hidden="true" focusable="false"><linearGradient id="purpleGradient" x2="1" y2="1"><stop offset="-11.24%" stop-color={variables.blueVioletColor}></stop><stop offset="59.06%" stop-color="#7965BF"></stop></linearGradient></svg>
                <ThanksIcon />
            </div>
          < h2 className={styles.title}>{this.props.title ? this.props.title : "Thank You!"}</h2>
          <p className={styles.subtitle}>{this.props.subtitle ? this.props.subtitle : (
            <span>We are reviewing the information that you provided. We'll be in touch in the next few days.</span>
            )}</p>
        </div>
        <div className={styles.homeLink}>
          <p className={styles.buttonDescripton}>Want to learn more about beatBread?</p>
          <Button2 className={styles.button2} dark outline full lg to={KNOWLEDGECENTER}>
              <p>Visit the Knowledge Center</p>
            <IconChevron />
          </Button2>
        </div>
      </div>
      );
    }
  }

class ErrorSection extends Component {
    constructor() {
      super();
      this.state = {};
    }
  
    componentDidMount() {
      segment.track.artistFailed()
    }
  
    render() {
      return (
        <div className={styles.content}>
        <div className={styles.titleContainer}>
            <div className={styles.icon}>
                <svg id={styles.svgGradient} aria-hidden="true" focusable="false"><linearGradient id="purpleGradient" x2="1" y2="1"><stop offset="-11.24%" stop-color={variables.blueVioletColor}></stop><stop offset="59.06%" stop-color="#7965BF"></stop></linearGradient></svg>
                <AlertIcon />
            </div>
          < h2 className={styles.title}>{this.props.title ? this.props.title : "Error!"}</h2>
          <p className={styles.subtitle}>{this.props.subtitle ? this.props.subtitle : (
            <span><b>Your form failed to submit!</b><br/>Try again in a few minutes.</span>
            )}</p>
        </div>
        <Button2 full onClick={this.props.onClick}>
            <p>Try Again</p><IconChevron />
        </Button2>
      </div>
      );
    }
  }
  
  export { ThankYouSection, ErrorSection };